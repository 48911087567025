import {Link} from 'react-router-dom';

const NavBar = () => {
    return (
        <div className='navbar'>
            <div className='navbarLeft'>
                <Link to='/' className='navbarLien'>Accueil</Link>
                <Link to='/parcours' className='navbarLien'>Parcours</Link>
                <Link to='/realisation' className='navbarLien'>Réalisation</Link>
            </div>
            <div className='navbarRight'>
                {/*<Link to='/register' className='navbarLien'>S'enregistrer</Link>*/}
                {/*<Link to='/login' className='navbarLien'>Se connecter</Link>*/}
            </div>
        </div>
    )
};

export default NavBar;