import './../App.css';
import fondHome from './../images/abstract-1278048_1920.jpg';
import DownloadButton from "./DownloadButton";

const Home = () => {

    return (
        <div className="homeContainer">
            <div className="textLeft">
                <h2><i>Olivier BUTTNER</i></h2>
                <div className="corpsTexte">
                    <h5>Développeur Full-Stack PHP et JavaScript</h5>
                    <p>Bonjour et bienvenue sur mon site. Je m'appelle Olivier et je suis développeur Web et SAAS.</p>
                    <p>Je travaille le PHP, natif ou avec Symfony, ainsi que le Javascript à travers React ou même
                        Vue3.
                    </p>
                    <p>Je suis diplomé d'une Licence en Développement Web et Innovation Numérique (DAWIN) obtenue à
                        l'IUT Informatique de Bordeaux. <br/>
                        J'ai obtenu la certification <a href="https://www.opquast.com/" target="_blank"
                                                        rel="noreferrer">Opquast</a>,
                        assurance qualité Web, avec le niveau "Avancé".
                    </p>
                    <p>Je recherche un poste sur Bordeaux et sa périphérie.</p>
                    <DownloadButton/>
                    <p>Mes outils favoris sont :
                        <ul>
                            <li>PhpStorm</li>
                            <li>PhpStan</li>
                            <li>Docker</li>
                            <li>Git</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className="pictureRight">
                <img src={fondHome} className='imgHome' alt='fond sur page home'/>
            </div>
        </div>
    )
};

export default Home;
