function DownloadButton() {
    function DownloadCV() {
        const link = document.createElement('a');
        link.href = "./BUTTNEROlivier_CV.pdf";
        link.download = 'BUTTNEROlivier_CV.pdf';
        link.click();
    };

    return (
        <div className="btnPosition">
            <button onClick={DownloadCV} className="btnDownload">
                Télécharger mon CV !
            </button>
        </div>
    )
};
export default DownloadButton;