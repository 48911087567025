import fondCV from "../images/abstract-1278048_1920.jpg";
import planning from "./../images/planning-med.png"
import aquitem from "./../images/aquitem.png"
import lissac from "./../images/lissac.png"
import cpam from "./../images/cpam.png"

const Parcours = () => {

    return (
        <div className="parcoursContainer">
            <div className="textLeft">
                <h2>Mon parcours</h2>
                <p>Vous trouverez le détail de ma vie professionnelle, en commençant par le plus récent jusqu'au plus
                    ancien. Il est divers, pluriel mais surtout il a été très enrichissant et formateur.</p>
                <ul>
                    <li className="listeParcours"><h3>Développeur concepteur Web Symfony/VueJS</h3><img src={cpam}
                                                                                                        className="logoEnt"
                                                                                                        alt="logo entreprise"
                    />

                    </li>
                    <p>
                        Aujourd'hui, j'ai intégré la CPAM de la Gironde. <br/>
                        Je suis développeur principal d'une solution nationale d'échange entre la CPAM et différentes structures partenaires.
                        Après une V1 qui a été adopté par 83 CPAM, un projet de V2 est décidé. J'ai été recruté pour la mettre en oeuvre.<br/><br/>
                        J'y ai réalisé une gestion d'actualité gérée par chaque CPAM vers l'ensemble de ces partenaires. Cette actualité est créée avec CKEditor.
                        L'affichage se fait à travers un carrousel automatiquement mis à jour à chaque nouvelle publication.
                        Bien sûr, qui dit actualité, dit newsletter.
                        Il y a bien d'autres fonctionnalités qui sont mises en place: échanges ping pong, CRON, etc...<br/>
                        J' y ai aussi découvert le CQRS et le DDD. Mettre au centre du projet le domaine Métier permet de s'assurer que la solution correspond bien aux attentes du client.<br/>
                        J'ai utilisé PhpUnit bien sûr, Phpstan au niveau max, Foundry et tout ce qui est versioning, Git.
                        L'accent est mis sur le clean code, le respect des conventions aussi bien celles de PHP queu celles de Symfony.
                        J'ai également intégré le roulement des astreintes de l'ensemble des applications de la CPAM Gironde.

                    </p>
                    <li className="listeParcours"><h3>Développeur web PHP/JavaScript</h3> <img src={planning}
                                                                                               className="logoEnt"
                                                                                               alt="logo entreprise"/>
                    </li>
                    <p>En 2023, j'ai obtenu la licence professionnelle DAWIN (Développement Web et
                        Innovation Numérique) à l'IUT de Bordeaux. <br/>
                        Cette année a été faite en alternance au sein de l'entreprise Planning-Service SARL située à St
                        Jean
                        d'Illac.<br/>
                        Son activité principale est le développement de <a href="https://www.planning-medical.com/" target="_blank" rel="noreferrer">Planning-Medical.com</a> qui est une application
                        SAAS de
                        génération et gestion de planning de services d'hôpitaux.
                        J'y ai vu le développement et la maintenance d'une application complexe et personnalisée.
                        Chaque développement doit être accompagné de ses tests fonctionnels associés. Cela m'a permis
                        d'améliorer ma vision de la résolution de problème.
                        Les technos utilisées sont PHP, Smarty, Python avec Selenium, Git et Jenkins.
                    </p>
                    <li className="listeParcours"><h3>Développeur Oracle</h3> <img src={aquitem} className="logoEnt"
                                                                                   alt="logo entreprise"/></li>
                    <p>J'ai réalisé mon stage de fin d'études bac+2 chez Aquitem/Fiducial. <br/>
                        <a href="https://www.groupe-aquitem.fr/" target="_blank" rel="noreferrer">Aquitem</a> est une entreprise
                        spécialisée dans les datas. Elle fait de l'hébergement, de l'analyse de données, de l'IA et
                        d'autres en lien avec les programmes fidélité.
                        J'ai intégré le bureau en charge du développement Oracle.
                        Ce fût une expérience très enrichissante. J'y ai vu le PL/SQL et l'optimisation des requêtes
                        afin d'avoir un niveau de performance optimale à chaque requête, même avec plusieurs millions
                        d'entrées.
                        Je remercie encore Cédric et Alain pour leur accompagnement.
                        Technos: Oracle et PHP.
                    </p>
                    <li className="listeParcours"><h3>Gérant ABM optique SARL</h3> <img src={lissac} className="logoEnt"
                                                                                        alt="logo entreprise"/></li>
                    <p>Une expérience marquante.<br/>
                        J'ai créé mon entreprise avec ma compagne et nous avons ouvert un magasin d'optique en tant que
                        franchisés sous l'enseigne <a href="https://www.lissac.fr/" target="_blank" rel="noreferrer">LISSAC</a>.
                        Durant ces quasiment 9 ans, j'ai pu me perfectionner sur tous les aspects propre à la gestion
                        courante d'une entreprise et d'une entité commerciale.<br/>
                        Que ce soit, la gestion client, le stock, la gestion commerciale et financière, le relationnel
                        fournisseur ou prescripteur, j'ai appris la rigueur, l'organisation, l'abnégation et tant
                        d'autres choses.<br/>
                        Une part de moi appréciera toujours ce métier. Un métier valorisant, gratifiant.
                    </p>
                    <li className="listeParcours"><h3>Opticien collaborateur</h3></li>
                    <p>Mon parcours d'opticien m'améné dans différentes structures. Indépendant ou souus enseigne. Poste
                        avec ou sans responsabilité.
                        J'ai surtout rencontré des gens dont certains sont devenus des amis.
                        Mon goût pour l'apprentissage s'en ai donné à coeuur joie! J'ai pu faire diverses formations
                        dont la relation client et l'analyse du besoin.
                    </p>
                </ul>
            </div>
            <div className="pictureRight">
                <img src={fondCV} className='imgHome' alt='fond sur page home'/>
            </div>
        </div>
    );

};

export default Parcours;