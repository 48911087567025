import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Caroussel = ({props}) => {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={2}
            spaceBetween={10}
            navigation
            pagination={{clickable: true}}
            scrollbar={{draggable: true}}
            breakpoints={{
                600: {
                    slidesPerView: 2,
                },
                400: {
                    slidesPerView: 1,
                },
            }}
            onSlideChange={() => console.log("slide changed")}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {props.map((prop) => {
                    return (
                        <SwiperSlide key={prop.url}>
                            <img src={prop.url} alt={prop.url} className="carrouselPicture"/>
                        </SwiperSlide>
                    )
                }
            )}
        </Swiper>
    );

};

export default Caroussel;