import axios from 'axios';
import {useEffect, useState} from "react";

const Meteo = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const API_KEY = "02a929f7353b4ee46486ddfa6b606872";


    useEffect(() => {
        axios
            .get(
                "https://api.openweathermap.org/data/2.5/weather?q=Bordeaux,fr&appid=" + API_KEY
            )
            .then((response) => {
                if (response.data.errors) {
                    console.log('Info non disponible');
                } else {
                    setData(response.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("error : ", error);
            });
    }, [isLoading]);

    return (
        <>
            {isLoading ? (
                <p>Chargement en cours ...</p>
            ) : (

                <div>
                    <h1>Météo à Bordeaux</h1>
                    <p>
                        <b>Température actuelle : </b>{ Math.round((data.main.temp - 273.15), 1) } °C
                    </p>
{/*                    <p>
                        <b>Conditions météo :</b> {data.weather[0].main}
                    </p>*/}
                </div>
            )}
        </>
    );

};

export default Meteo;