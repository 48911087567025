import Meteo from "./Meteo";
import Caroussel from "./Carroussel";
import baladesSlides from '../balades.json';

const Realisation = () => {

    return (
        <div className="text-center">
            <h2>Mes réalisations</h2>
            <p>Vous trouverez des exemples des projets que j'ai pu réaliser.</p>

            <ul>
                <li>
                    <h4>Mon CV en ligne</h4>
                    <p>Tout d'abord, ce site.
                        Il est réalisé en React. J'y utilise Router, CSS, Axios pour les appels API et Swiper.
                        Il sera enrichi de futures fonctionnalités et d'autres exemples au fur et à mesure.
                    </p>
                </li>
                <li>
                    <h4>Balades Immersives</h4>
                    <p>Il s'agit de mon projet de fin de Licence Pro. Il a été demandé par le service expansion de
                        l'Université de Bordeaux.<br/>
                        C'est une application d'immersion 3D dans les bâtiments.
                        Il y a un back en Express JS qui s'occupe de l'enregistrement des photos 3D, de leur envoi lors
                        de l'"entrée" dans un bâtiment. Il gère également l'enregistrement des "points d'intêrets" que
                        ce soit des infobulles ou des flèches de déplacement.<br/>
                        Le front est réalisé en React. Il utilise Router, ThreeJS, Leaflet et Axios.
                        Il réalise des appels pour afficher les photos, positionne les bâtiments photographiés sur
                        une carte.
                    </p>
                    <p>Le site etait déployé en ligne via un VPS pour le back. Etait, malheureusement, car le VPS a été
                        désactivé par l'IUT. Et via GitHub Actions pour le Front. Les 2 étaient en SSL.
                    </p>
                    <Caroussel props={baladesSlides}/>
                </li>
            </ul>


            <Meteo/>
        </div>
    );
};

export default Realisation;