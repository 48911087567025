import './App.css';
import Home from "./Components/Home";
import {Route, Routes} from "react-router-dom";
import React from "react";
import NavBar from "./Components/NavBar";
import Parcours from "./Components/Parcours";
import Realisation from "./Components/Realisation";

function App() {
    return (
        <div className="App">
            <NavBar/>
            <header className="App-header text-center">
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/parcours" element={<Parcours/>}/>
                    <Route path="/realisation" element={<Realisation/>}/>
                </Routes>
            </header>
        </div>
    );
}

export default App;
